import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const useInputState = (
  initialState: string | (() => string)
): [string, React.ChangeEventHandler, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState(initialState);
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setValue(event.target.value),
    []
  );

  return [value, onChange, setValue];
};
