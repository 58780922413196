import React, { useDeferredValue, useMemo } from 'react';
import { Typography, Input, Divider, Card, Empty } from 'antd';
import { useInputState } from '../../../helpers';
import { prepareDataQas } from './helpers';
import styles from './DataQa.module.css';

export const DataQa = () => {
  const [input, setInput] = useInputState('');
  const deferredInput = useDeferredValue(input);
  const result = useMemo(() => prepareDataQas(deferredInput), [deferredInput]);

  return (
    <Card title="Data-qa">
      <Input.TextArea
        rows={5}
        value={input}
        onChange={setInput}
        placeholder="Input"
      />
      <Divider />
      <Typography.Title level={5}>Result</Typography.Title>
      {result ? (
        <pre className={styles.code}>
          <Typography.Paragraph copyable>{result}</Typography.Paragraph>
        </pre>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};
