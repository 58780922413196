export const snippets = [
  [
    [
      {
        span: 8,
        title: 'Greatest Common Divider',
        code: `const gcd = (x, y) => {
    while(y) {
        const t = y;
        y = x % y;
        x = t;
    }
    return x;
}`,
      },
      {
        span: 8,
        title: 'Least Common Multiple',
        code: `const lcm = (x, y) => Math.round(x * y / gcd(x, y));`,
      },
      {
        span: 8,
        title: 'Binary search',
        code: `const findBinary = (arr, el, start = 0, end = arr.length - 1) => {
    while (start < end) {
        const mid = (start + end) >> 1;
        if (el <= arr[mid]) {
            end = mid;
        } else {
            start = mid + 1;
        }
    }
    
    return arr[start] === el && start;
}`,
      },
      {
        span: 8,
        title: 'Factorial',
        code: `const factorialCache = { 0: 1 };
const factorial = (n) => {
    if (n in factorialCache) {
        return factorialCache[n];
    }

    return (factorialCache[n] = n * factorial(n - 1));
}`,
      },
    ],
    [
      {
        span: 8,
        title: 'Modulo divide',
        code: `const binPow = (base,  p) => {
    if (p === 1) {
        return base;
    }

    if (p % 2 === 0) {
        const t = binPow(base, Math.floor(p / 2));
        return t * t % modulo;
    } else {
        return binPow(base, p - 1) * base % modulo;
    }
}

const inverseElement = (x) => {
    return binPow(x, modulo - 2);
}

const divide = (a, b) => {
    return a * inverseElement(b) % modulo;
}`,
      },
      {
        span: 8,
        title: 'Permutations',
        code: `const permute = nums => {
    if (nums.length <= 1) {
        return [nums];
    }

    const result = [];
    const [head, ...tail] = nums;

    permute(tail).forEach(p => {
        for (let i = 0; i <= p.length; i++) {
            result.push([...p.slice(0, i), head, ...p.slice(i)]);
        }
    })

    return result;
};`,
      },
      {
        span: 8,
        title: 'Counter',
        code: `const countValues = (arr) => {
    return arr.reduce((acc, v) => {
        acc[v] = (acc[v] || 0) + 1;
        return acc;
    }, {});
}`,
      },
    ],
    [
      {
        span: 8,
        title: 'Is prime',
        code: `const primeCache = {};
const isPrime = (n) => {
    if (n in primeCache) {
        return primeCache[n];
    }
    
    const sqrt = Math.sqrt(n);
    for (let i = 2; i <= sqrt; i++) {
        if (n % i === 0) {
            return primeCache[n] = false;
        }
    }
    
    return primeCache[n] = true;
}`,
      },
      {
        span: 8,
        title: 'Get prime factors',
        code: `const getPrimeFactors = (n) => {
    if (isPrime(n)) {
        return [n];
    }
    
    const result = [];
    let i = 2;
    
    while (n > 1) {
        if (n % i === 0) {
            result.push(i);
            n /= i;
            continue;
        }
        
        i++;
    }
    
    return result;
}`,
      },
      {
        span: 8,
        title: 'Longest Increasing Subsequence',
        code: `class LIS {
    constructor(n) {
        this.lis = Array(n);
        this.size = 0;
    }

    find(h) {
        let low = 0, high = this.size;
        while (low <= high) {
            const mid = (low + high) >> 1;
            if (h >= this.lis[mid]) {
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }

        if (low === this.size) {
            this.size++;
        }
        this.lis[low] = h;

        return low + 1;
    }
}`,
      },
    ],
  ],
];
