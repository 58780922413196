export const splitTranslations = (
  rawTranslations = '',
  translationKeys = ''
) => {
  if (!rawTranslations) {
    return [];
  }

  try {
    const keys = translationKeys.split('\n');
    const translations = rawTranslations
      .split('\n')
      .map((line) => line.split('/').map((s) => s.trim()));

    if (!translations.length) {
      return [];
    }

    return translations[0].map((_, langIndex) => {
      return translations
        .map(
          (translation, translationIndex) => `,
    "${keys[translationIndex] || ''}": "${translation[langIndex]}"`
        )
        .join('');
    });
  } catch {
    return [];
  }
};
