import React, { useDeferredValue, useMemo } from 'react';
import { Typography, Input, Divider, Card, Empty, Row, Col } from 'antd';
import { useInputState } from '../../../helpers';
import { splitTranslations } from './helpers';
import styles from './Translations.module.css';

export const Translations = () => {
  const [input, setInput] = useInputState('');
  const [translationKeys, setTranslationKeys] = useInputState('');
  const deferredTranslationKeys = useDeferredValue(translationKeys);

  const translations = useMemo(
    () => splitTranslations(input, deferredTranslationKeys),
    [input, deferredTranslationKeys]
  );

  const span = Math.floor(24 / translations.length);

  return (
    <Card title="Translations">
      <Row justify="space-between">
        <Col span={17}>
          <Input.TextArea
            rows={10}
            value={input}
            onChange={setInput}
            placeholder="Input"
          />
        </Col>
        <Col span={6}>
          <Input.TextArea
            rows={10}
            value={translationKeys}
            onChange={setTranslationKeys}
            placeholder="Translation keys"
          />
        </Col>
      </Row>
      <Divider />
      <Typography.Title level={5}>Result</Typography.Title>
      {translations.length ? (
        <Row>
          {translations.map((translation, langIndex) => {
            return (
              <Col span={span} key={langIndex}>
                <pre className={styles.code}>
                  <Typography.Paragraph copyable>
                    {translation}
                  </Typography.Paragraph>
                </pre>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};
