import React from 'react';
import { Row, Col, Card } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { snippets } from './snippets';
import styles from './LeetCode.module.css';
import { CopyOutlined } from '@ant-design/icons';

export const LeetCode = () => {
  return (
    <>
      {snippets.map((row, rowKey) => (
        <Row key={rowKey} gutter={16}>
          {row.map((col, colKey) => (
            <Col key={colKey} span={col[0]?.span}>
              {(Array.isArray(col) ? col : [col]).map(({ title, code }) => (
                <Card
                  title={
                    <span>
                      {title}
                      <CopyOutlined
                        className={styles.copyIcon}
                        onClick={() => navigator.clipboard.writeText(code)}
                      />
                    </span>
                  }
                  key={title}
                  className={styles.card}
                >
                  <SyntaxHighlighter language="javascript" style={darcula}>
                    {code}
                  </SyntaxHighlighter>
                </Card>
              ))}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};
