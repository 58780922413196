const qaLineRegex = /^\[\d+]\s*=\s*([a-zA-Z_{}]+)$/;

export const prepareDataQas = (rawDataQa = '') => {
  try {
    return rawDataQa
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line)
      .map((line) => {
        const matchResult = qaLineRegex.exec(line);
        if (!matchResult) {
          return line;
        }

        return matchResult[1];
      })
      .map((line) => {
        if (line.toUpperCase().endsWith('{ID}')) {
          const baseLine = line.slice(0, -4);
          const lineWithoutUnderscore = baseLine.endsWith('_')
            ? baseLine.slice(0, -1)
            : baseLine;

          return `export const ${lineWithoutUnderscore} = (index: number) => \`${lineWithoutUnderscore}_\${index}\`;`;
        }

        return `export const ${line} = '${line}';`;
      })
      .join('\n');
  } catch {
    return '';
  }
};
