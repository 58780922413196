import React from 'react';
import { Row, Col, Divider } from 'antd';
import { DataQa } from './DataQa';
import { Translations } from './Translations';

export const Work = () => {
  return (
    <>
      <Row>
        <Col span={16}>
          <DataQa />
        </Col>
        <Divider />
        <Col span={16}>
          <Translations />
        </Col>
      </Row>
    </>
  );
};
